<template>
  <div class="d-flex fill-height" ref="wrapper" v-resize="setParentHeight">
    <div class="d-flex flex-column flex-fill">
      <!--      <div class="d-flex mb-2" v-if="carouselFiles.length > slidesToScroll">-->
      <!--        <UiBtn icon class="mx-auto " color="gray-60" @click="prevSlide" :disabled="currentPage <= 1">-->
      <!--          <IconArrowUp width="18"/>-->
      <!--        </UiBtn>-->
      <!--      </div>-->

      <div class="flex-initial assets-list pr-1">
        <UIFilesUploader
          :files-formats="
            $config.project.modulesFileFormats[getActiveDesignModule.slug]
          "
          :max-file-size="
            $config.filesystem.project.design.file.maxUploadFileSize
          "
          multiple
          :disabled="!abilityToUpload"
          @onChange="
            (files) => $emit('createDesign', { files, newVersion: false })
          "
          class="design-view-upload-file mx-auto fill-width d-flex flex-column align-center justify-center relative rounded mb-1 text-center white gray-60--text overflow-hidden cursor-pointer"
        >
          <IconUpload width="25" class="mb-3 accent--text" />
          <div class="text-captions-2 font-weight-light">
            <template v-if="abilityToUpload">
              Drag and Drop here
              <p
                class="mb-0 accent--text font-weight-medium text-decoration-underline"
              >
                or browse to add
              </p>
            </template>
            <template v-else>
              You don't have permissions to upload files into
              {{ getActiveDesignModule.title }} module
            </template>
          </div>
        </UIFilesUploader>
        <!--        <VueSlickCarousel-->
        <!--          v-if="carouselFiles.length && initGallery"-->
        <!--          :draggable="false"-->
        <!--          :infinite="false"-->
        <!--          :swipe="false"-->
        <!--          :touchMove="false"-->
        <!--          vertical-->
        <!--          class="file-carousel space-y-3"-->
        <!--          :slidesToShow="slidesToScroll"-->
        <!--          :slidesToScroll="slidesToScroll"-->
        <!--          :initialSlide="initPage"-->
        <!--          :arrows="false"-->
        <!--          ref="carousel"-->
        <!--          @beforeChange="beforeChange"-->
        <!--        >-->
        <!--          <div class="py-1" v-for="(file) in carouselFiles" :key="file.id">-->
        <!--            <FilePreviewCard :file="file" @click.stop="go(file)"-->
        <!--                             :abilityToUpload="abilityToUpload"-->
        <!--                             @newVersion="files => $emit('createDesign', {files, newVersion: true})"-->
        <!--                             :class="{'file-slide__active': +file.gallery_group_id === +$route.query.gallery_group_id}" @delete="deleteItems"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <template #nextArrow>-->
        <!--            <UiBtn icon class="my-auto" color="gray-60">-->
        <!--              <IconArrowDown width="18"/>-->
        <!--            </UiBtn>-->
        <!--          </template>-->
        <!--        </VueSlickCarousel>-->
        <div class="py-1" v-for="file in carouselFiles" :key="file.id">
          <FilePreviewCard
            :file="file"
            @select="selectFile($event, file)"
            @click.stop="go(file)"
            :abilityToUpload="abilityToUpload"
            @newVersion="
              (files) => $emit('createDesign', { files, newVersion: true })
            "
            :class="{
              'file-slide__active':
                +file.gallery_group_id === +$route.query.gallery_group_id,
            }"
            @delete="deleteItems"
          />
        </div>
      </div>
      <!--      <div class="d-flex mt-2" v-if="carouselFiles.length > slidesToScroll">-->
      <!--        <UiBtn icon class="mx-auto" color="gray-60" @click="nextSlide" :disabled="currentPage >= totalPageCount">-->
      <!--          <IconArrowDown width="18"/>-->
      <!--        </UiBtn>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import { mapGetters } from "vuex";
import { getFileExtension, getFileName } from "@/utils/helpers";
import FilePreviewCard from "@/views/project/design-view/FilePreviewCard.vue";

export default {
  name: "GalleryCarousel",
  props: {
    abilityToUpload: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elHeight: 0,
      parentHeight: 0,
      sliderPageIndex: 1,
      initPage: 0,
      initGallery: true,
      selectedFiles: [],
    };
  },
  components: {
    FilePreviewCard,
    IconUpload: () => import("@/components/icons/IconUpload"),
    // IconArrowUp: () => import('@/components/icons/IconArrowUp'),
    // IconArrowDown: () => import('@/components/icons/IconArrowDown'),

    // VueSlickCarousel,
    // UiBtn,
    UIFilesUploader,
  },
  watch: {
    carouselFilesLength() {
      this.reinitGallery();
    },
    fullscreen() {
      this.setParentHeight();
    },
  },
  computed: {
    ...mapGetters([
      "getProject",
      "getActiveStyle",
      "getActiveFile",
      "getActiveDesignModule",
      "getPermission",
      "getProjectPermissionData",
    ]),
    slidesToScroll() {
      const CONTROLS_SIZE = 92;
      const SLIDE_HEIGHT = 124;
      return Math.max(
        Math.floor((this.elHeight - CONTROLS_SIZE) / SLIDE_HEIGHT) - 1,
        1
      );
    },
    currentPage() {
      // always display 1 if page index is 0
      if (this.sliderPageIndex === 0) {
        return 1;
      } else {
        // compensate for slidesToScroll settings and compensate the shift by 1
        return Math.ceil(this.sliderPageIndex / this.slidesToScroll + 1);
      }
    },
    totalPageCount() {
      return Math.ceil(this.carouselFilesLength / this.slidesToScroll);
    },
    groupedItems() {
      return this.getActiveStyle?.grouped_items || {};
    },
    carouselFiles() {
      return (
        Object.values(this.groupedItems)?.map((item) => {
          return {
            ...item[0],
            can_delete: item.every((file) => this.deleteDisable(file)),
            all_ids: item.map((file) => file.id),
          };
        }) || []
      );
    },
    carouselFilesLength() {
      return this.carouselFiles.length;
    },
    deleteDisable() {
      const { projectOwner, projectManager } = this.$config.project.userRole;
      return (item) => {
        return (
          (item.created_by === this.getAuthId ||
            this.getProjectPermissionData(
              this.$route.params.project_id
            ).roles.some(
              (role) => role === projectOwner || role === projectManager
            )) &&
          this.getPermissionByModule("can-delete-file-design")
        );
      };
    },
  },
  mounted() {
    this.reinitGallery();
  },
  methods: {
    getFileExtension,
    getFileName,
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      const activeModule = this.getProject.modules.find(
        (module) => module.id === +this.$route.query.module_id
      );
      return (
        this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(
          activeModule.slug
        )
      );
    },
    selectFile(status, file) {
      this.selectedFiles = this.selectedFiles
        .filter(item => item.id !== file.id)
        .concat(status ? [{ selected: true, ...file }] : []);
      this.$store.dispatch("setActiveFiles", this.selectedFiles);
    },

    nextSlide() {
      this.$refs.carousel.next();
    },
    prevSlide() {
      this.$refs.carousel.prev();
    },
    beforeChange(oldPage, newPage) {
      this.sliderPageIndex = newPage;
    },
    setParentHeight() {
      this.$nextTick(() => {
        this.elHeight = this.$refs.wrapper.clientHeight;
        this.reinitGallery();
      });
    },
    deleteItems(fileDeleted) {
      if (!fileDeleted.can_delete) return;

      this.$store.dispatch("openModal", {
        modalName: "confirmModal",
        data: {
          title: `Are you sure you want to delete all files?`,
          confirmBtnText: "Delete",
        },
        handlers: {
          onConfirm: async () => {
            this.$store.dispatch("closeModal", "confirmModal");

            try {
              await this.$api.projectModuleDesign.deleteFiles(
                this.$route.params.project_id,
                this.$route.query.module_id,
                { items: fileDeleted.all_ids }
              );

              const items = this.getActiveStyle.items.filter(
                (file) => !fileDeleted.all_ids.includes(file.id)
              );
              const groupIdx = +Object.keys(
                this.getActiveStyle.grouped_items
              ).indexOf(String(fileDeleted.gallery_group_id));

              this.$store.dispatch("setActiveStyle", {
                ...this.getActiveStyle,
                items,
              });

              if (
                this.getActiveStyle.grouped_items &&
                !this.getActiveStyle.grouped_items[fileDeleted.gallery_group_id]
              ) {
                const id = Object.keys(this.getActiveStyle.grouped_items)[
                  groupIdx <= 0 ? 0 : groupIdx - 1
                ];
                this.$router.$updateQueryParams({ gallery_group_id: id });
                if (this.getActiveStyle.grouped_items[id]) {
                  this.$store.dispatch("setActiveFile", {
                    ...this.getActiveStyle.grouped_items[id][0],
                    loading: true,
                  });
                  this.$router.$updateQueryParams({
                    file_id: this.getActiveStyle.grouped_items[id][0].id,
                  });
                } else {
                  this.$store.dispatch("setActiveFile", null);
                  await this.$store.dispatch('setActiveFiles', []);

                  this.$router.$updateQueryParams({ file_id: "" });
                }
              } else if (!this.getActiveStyle.grouped_items) {
                this.$router.$updateQueryParams({ gallery_group_id: "" });
                this.$store.dispatch("setActiveFile", null);
                await this.$store.dispatch('setActiveFiles', []);

                this.$router.$updateQueryParams({
                  file_id: "",
                  gallery_group_id: "",
                });
              }
            } catch (error) {
              console.error(error);
            } finally {
              const items = this.getActiveStyle.items.map((file) =>
                file.loading ? { ...file, loading: true } : file
              );
              this.$store.dispatch("setActiveStyle", {
                ...this.getActiveStyle,
                items,
              });

              // we need to load the project to check if the module has files
              // if module don't have any files, it will be available to remove
              const project = await this.$api.project.get(this.getProject.id);
              this.$store.dispatch("setProject", project.data);
              this.$store.dispatch("setDesignModules", project.data.modules);
            }
          },
          onCancel: () => {
            this.$store.dispatch("closeModal", "confirmModal");
          },
        },
      });
    },
    go(file) {
      if (this.$route.query.gallery_group_id === file.gallery_group_id) return;
      this.$router.$updateQueryParams({
        gallery_group_id: file.gallery_group_id,
        file_id: file.id,
      });
      this.$store.dispatch("setActiveFile", file);
    },
    init() {
      const index = this.groupedItems
        ? Object.keys(this.groupedItems).indexOf(
            String(this.$route.query.gallery_group_id)
          )
        : 0;
      const fixedIndex = (index < 0 ? 0 : index) + 1;
      this.initPage =
        Math.ceil(fixedIndex / this.slidesToScroll) * this.slidesToScroll -
        this.slidesToScroll;
      this.$nextTick(() => {
        this.$refs.carousel?.goTo?.(this.initPage);
      });
    },
    reinitGallery() {
      this.initGallery = false;
      this.$nextTick(() => {
        this.initGallery = true;
        this.init();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.design-view-upload-file {
  box-shadow: 0 0 0 1px var(--v-gray-30-base);
  height: 88px;
  width: 118px;
  z-index: 1;

  &.active-dropzone {
    box-shadow: 0 0 0 1px var(--v-accent-base);
  }
}

:deep(.slick-vertical .slick-slide) {
  border: none !important;
  height: 124px !important;
}
.assets-list {
  padding-top: 2px;
  max-height: 100%;
  overflow-y: auto;
  background-color: transparent;

  scrollbar-color: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--v-gray-0-base);
    border: 1px solid var(--v-gray-30-base) !important;
    box-sizing: border-box;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-gray-60-base) !important;
    border: 2px solid transparent !important;
    border-radius: 12px !important;
    background-clip: content-box;
  }
}
</style>
