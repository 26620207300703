<template>
  <div class="file-slide d-flex flex-column relative rounded">
    <UiSelectedIndicator
      class="absolute select-item"
      :selected="selected"
      @click.self="selectFile()"
    />
    <button
      type="button"
      class="absolute delete-item d-flex align-center justify-center primary--text"
      v-if="file.can_delete"
      @click="deleteItems(file)"
    >
      <IconCancelCircle width="16" />
    </button>
    <div
      v-on="$listeners"
      class="overflow-hidden d-flex align-center justify-center fill-width file-preview"
    >
      <UIImage
        class="fill-width fill-height object-cover"
        v-if="!file.file"
        :name-path="`default-img.png`"
      />
      <img
        v-else-if="
          $config.filesystem.fileTypes.image.includes(
            getFileExtension(file.file.original_name)
          )
        "
        :src="getFileThumbnails(file.file.thumbnails) || file.file.url"
        alt=""
        class="fill-width fill-height object-cover"
      />
      <UiFileIcon
        v-else
        width="36"
        :extension="getFileName(file.file.original_name).ext"
      />
    </div>
    <div
      class="bottom-bar d-flex align-center justify-space-between px-2 flex-auto"
    >
      <div
        class="accent--text d-flex align-center font-weight-semi-bold text-captions-2"
      >
        <UiProjectStatus
          :id="file.status"
          circle
          class="status-indicator mr-1"
        />
        <IconCopy width="10" class="mr-1 accent--text" />
        V {{ file.version }}
      </div>
      <FilePreviewActionsMenu
        :file="file"
        :abilityToUpload="abilityToUpload"
        :canDelete="file.can_delete"
        @newVersion="$emit('newVersion', $event)"
        @deleteFile="deleteItems"
      />
    </div>
  </div>
</template>

<script>
import UIImage from "@/components/UI/UIImage.vue";
import UiProjectStatus from "@/components/UI/UiProjectStatus.vue";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";
import {
  getFileExtension,
  getFileName,
  getFileThumbnails,
} from "@/utils/helpers";
import FilePreviewActionsMenu from "@/views/project/design-view/FilePreviewActionsMenu.vue";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator.vue";

export default {
  components: {
    UiSelectedIndicator,
    FilePreviewActionsMenu,
    IconCancelCircle: () => import("@/components/icons/IconCancelCircle"),
    IconCopy: () => import("@/components/icons/IconCopy"),
    UiFileIcon,
    UiProjectStatus,
    UIImage,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    abilityToUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    getFileThumbnails,
    getFileExtension,
    getFileName,
    deleteItems(file) {
      this.$emit("delete", file);
    },
    selectFile() {
      this.selected = !this.selected;
      this.$emit("select", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-slide {
  width: 118px !important;
  height: 116px !important;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 0 1px var(--v-primary-base);
  cursor: pointer;

  .file-preview {
    height: 88px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .bottom-bar {
    border-top: 1px solid var(--v-primary-base);
  }

  &:not(&__active):hover {
    box-shadow: 0 0 0 1px var(--v-primary-base);
  }

  &__active {
    box-shadow: 0 0 0 2px var(--v-primary-base);
  }

  :deep(.status-indicator) {
    top: 4px;
    left: 4px;
    z-index: 1;

    &.status-circle {
      .circle {
        width: 10px;
        height: 10px;
        border: 1px solid #ffffff;
      }
    }
  }

  .delete-item {
    top: 4px;
    right: 4px;
    z-index: 1;
    background: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }

  .select-item {
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
  }

  .download-item {
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 1;
  }
}
</style>
