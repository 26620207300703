var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UiMenu',{attrs:{"top":_vm.bottomActions,"bottom":!_vm.bottomActions,"left":_vm.$vuetify.breakpoint.xsOnly && _vm.bottomActions,"offset-y":"","nudge-top":_vm.bottomActions ? 8 : -8,"min-width":_vm.$vuetify.breakpoint.xsOnly && _vm.bottomActions ? 'calc(100% - 24px)' : 155,"z-index":"10"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('UiBtn',_vm._g(_vm._b({attrs:{"icon":!_vm.bottomActions,"color":_vm.bottomActions ? 'accent' : 'gray-60'}},'UiBtn',attrs,false),on),[(!_vm.bottomActions)?_c('IconDotsH',{attrs:{"width":"14"}}):[_c('IconArrowUp',{staticClass:"mr-2",attrs:{"width":"14"}}),_vm._v(" More Actions ")]],2)]}}])},[_c('v-list',{attrs:{"nav":"","dense":"","color":"gray-10"}},[(!_vm.hidePartMenu)?_c('v-list-item',{attrs:{"disabled":!_vm.getPermission(_vm.$route.params.project_id).design['can-share-design'] ||
        true}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconShare',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Share design ")],1)],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":_vm.fileLoading || !this.getActiveFile},on:{"click":_vm.downloadFile}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconDownload',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Download design ")],1)],1),(!_vm.hidePartMenu)?_c('v-list-item',{attrs:{"disabled":!_vm.abilityToRequestReview},on:{"click":_vm.requestReview}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconSendMessage',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Request review ")],1)],1):_vm._e(),(!_vm.hidePartMenu)?_c('v-list-item',{attrs:{"disabled":_vm.checkIfDisabled(_vm.$config.project.status.rejected) || !_vm.abilityToReview},on:{"click":function($event){return _vm.approveOrReject(_vm.$config.project.status.rejected)}}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconCancel',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Request adjustments ")],1)],1):_vm._e(),(!_vm.hidePartMenu)?_c('v-list-item',{attrs:{"disabled":_vm.checkIfDisabled(_vm.$config.project.status.approved) || !_vm.abilityToReview},on:{"click":function($event){return _vm.approveOrReject(_vm.$config.project.status.approved)}}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconCheck',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Approve design ")],1)],1):_vm._e(),(
        !_vm.hidePartMenu &&
        ((_vm.bottomActions && _vm.$vuetify.breakpoint.xsOnly) || !_vm.bottomActions)
      )?_c('v-list-item',{attrs:{"disabled":_vm.checkIfDisabled(_vm.$config.project.status.archived) ||
        !_vm.getPermissionByModule('can-archive-design')},on:{"click":function($event){return _vm.updateStatus('archive')}}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconArchive',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Archive design ")],1)],1):_vm._e(),(
        !_vm.hidePartMenu &&
        ((_vm.bottomActions && _vm.$vuetify.breakpoint.xsOnly) || !_vm.bottomActions)
      )?_c('v-list-item',{attrs:{"disabled":!this.getActiveFile || !_vm.canDelete},on:{"click":_vm.deleteItems}},[_c('v-list-item-title',{staticClass:"text-captions-1"},[_c('IconDelete',{staticClass:"mr-2 gray-60--text",attrs:{"width":"14"}}),_vm._v(" Delete design ")],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }