<template>
  <div class="design-view-page">
    <v-flex v-if="loading" class="d-flex justify-center align-center py-16">
      <v-progress-circular :size="100" color="accent" indeterminate />
    </v-flex>

    <template v-else>
      <PageHeader :sticky="false">
        <div class="d-flex align-center justify-space-between mb-8 space-x-4">
          <div
            class="text-title-1 d-flex align-center space-x-4 overflow-hidden"
          >
            <div class="d-flex align-center overflow-hidden">
              <UiBtn icon class="mr-1" @click="goBack" color="gray-60" exact>
                <IconArrowLeft width="16" style="margin-top: 2px" />
              </UiBtn>

              <router-link
                :to="{
                  name: 'ProjectDesign',
                  params: { project_id: this.$route.params.project_id },
                  query: { module: this.$route.query.module_id },
                }"
                class="project-title gray-80--text text-decoration-none flex-shrink-0 text-truncate"
              >
                {{ this.getProject.title }}
              </router-link>
              <IconDoubleChevronRight
                width="16"
                class="mx-2 accent-70--text flex-shrink-0"
              />
              <v-tooltip top color="rgba(47, 49, 53, 0.7)">
                <template #activator="{ attrs, on }">
                  <span class="text-truncate" v-bind="attrs" v-on="on"
                    >{{ getActiveStyle.title }}
                  </span>
                </template>
                <div class="text-captions-1">
                  {{ getActiveStyle.title }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <div class="space-x-3 flex-shrink-0 d-flex align-center">
            <div>
              <SingleItemDueDate
                v-if="
                  $vuetify.breakpoint.smAndUp &&
                  getActiveStyle?.status !== $config.project.status.approved &&
                  !loading
                "
                type="module-style"
                :active-style="getActiveStyle"
                @updateData="updateDesignDueDate($event)"
              />
            </div>
            <UiBtn
              outlined
              color="accent"
              :to="{
                name: 'ProjectEdit',
                params: { project_id: $route.params.project_id },
              }"
            >
              <IconSlidersV width="16" class="mr-2" />
              Specifications
            </UiBtn>
            <UiBtn
              v-if="projectMoodBoardModule"
              outlined
              color="accent"
              :to="{
                name: 'MoodBoard',
                params: { project_id: $route.params.project_id },
              }"
            >
              <IconMoodboard width="16" class="mr-2" />
              Whiteboard
            </UiBtn>
            <UiBtn
              fab
              color="accent"
              class="d-lg-none"
              :disabled="getProject.id === null"
              @click="
                $store.dispatch(
                  'toggleProjectSideDrawer',
                  !showProjectSideDrawer
                )
              "
            >
              <IconExpandRight width="16" v-if="showProjectSideDrawer" />
              <IconExpandLeft width="16" v-else />
            </UiBtn>
          </div>
        </div>
        <div class="d-flex align-center space-x-3">
          <ModulesNavigation
            :modules="getDesignModules"
            v-model="moduleId"
            class="flex"
          />
          <UiMenu offset-y nudge-bottom="4" nudge-left="8" :z-index="10">
            <template #activator="{ on, attrs }">
              <UiBtn fab color="accent" v-bind="attrs" v-on="on">
                <IconPlus width="16" />
              </UiBtn>
            </template>
            <v-list nav dense color="gray-10">
              <UIFilesUploader
                :files-formats="
                  $config.project.modulesFileFormats[getActiveDesignModule.slug]
                "
                :max-file-size="
                  $config.filesystem.project.design.file.maxUploadFileSize
                "
                multiple
                :disabled="!abilityToUpload"
                @onChange="createDesign($event, false)"
                class="d-flex"
              >
                <v-list-item
                  ripple
                  class="cursor-pointer"
                  :disabled="!abilityToUpload"
                >
                  <v-list-item-title class="text-caption-1">
                    <IconUpload width="14" class="mr-2 gray-60--text" />
                    Upload file
                  </v-list-item-title>
                </v-list-item>
              </UIFilesUploader>

              <UIFilesUploader
                :files-formats="allowedExtensionTypeForModule"
                :max-file-size="
                  $config.filesystem.project.design.file.maxUploadFileSize
                "
                multiple
                v-if="$route.query.gallery_group_id"
                :disabled="!abilityToUpload"
                @onChange="createDesign($event, true)"
                class="d-flex"
              >
                <v-list-item
                  ripple
                  class="cursor-pointer"
                  :disabled="!abilityToUpload"
                >
                  <v-list-item-title class="text-caption-1">
                    <IconPlus width="14" class="mr-2 gray-60--text" />
                    Create new version
                  </v-list-item-title>
                </v-list-item>
              </UIFilesUploader>
            </v-list>
          </UiMenu>
        </div>
        <SideDrawerControls class="d-none d-lg-flex" />
        <v-expand-transition>
          <FilesCarousel
            :abilityToUpload="abilityToUpload"
            v-if="showFilesCarousel && getActiveStyle.items.length > 0"
            :fileLoading="fileLoading"
            @createDesign="createDesign($event.files, $event.newVersion)"
          />
        </v-expand-transition>
      </PageHeader>

      <v-container class="py-5 relative view-container">
        <v-responsive v-if="moduleLoading" :aspect-ratio="878 / 652">
          <v-flex
            class="module-loading d-flex justify-center align-center white absolute inset-0"
          >
            <v-progress-circular :size="100" color="accent" indeterminate />
          </v-flex>
        </v-responsive>
        <FileView
          v-else
          :abilityToUpload="abilityToUpload"
          :fileLoading="fileLoading"
          @createDesign="createDesign($event.files, $event.newVersion)"
          @updateModuleId="moduleId = $event"
        />
        <DesignViewPageNote
          v-if="
            activeFileNoteData &&
            getActiveFile.status !== $config.project.status.archived
          "
          :activeFileNoteData="activeFileNoteData"
        />
      </v-container>

      <PageFooter class="project-footer">
        <FileBottomActions />
      </PageFooter>

      <RequestReviewModal
        v-if="getModal('requestReviewModal').data.files.length"
        :active-module-id="+this.$route.params.module_id"
        @requestReview="requestReview"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileView from "@/views/project/design-view/FileView";
import FileBottomActions from "@/views/project/design-view/FileBottomActions";
import FilesCarousel from "@/views/project/design-view/FilesCarousel";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import UiBtn from "@/components/UI/UiBtn";
import ModulesNavigation from "@/components/project/ModulesNavigation";
import UiMenu from "@/components/UI/UiMenu";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import RequestReviewModal from "@/components/modals/RequestReviewModal";
import { getFileExtension } from "@/utils/helpers";
import SideDrawerControls from "@/components/project/side-drawer/SideDrawerControls.vue";
import DesignViewPageNote from "@/views/project/design-view/DesignViewPageNote.vue";
import SingleItemDueDate from "@/views/project/design-list/SingleItemDueDate.vue";
import IconDoubleChevronRight from "@/components/icons/IconDoubleChevronRight.vue";

export default {
  name: "DesignViewPage",
  components: {
    SingleItemDueDate,
    IconDoubleChevronRight,
    DesignViewPageNote,
    SideDrawerControls,
    IconArrowLeft: () => import("@/components/icons/IconArrowLeft"),
    IconSlidersV: () => import("@/components/icons/IconSlidersV"),
    IconMoodboard: () => import("@/components/icons/IconMoodboard"),
    IconExpandRight: () => import("@/components/icons/IconExpandRight"),
    IconExpandLeft: () => import("@/components/icons/IconExpandLeft"),
    IconPlus: () => import("@/components/icons/IconPlus"),
    IconUpload: () => import("@/components/icons/IconUpload"),

    UIFilesUploader,
    UiMenu,
    ModulesNavigation,
    UiBtn,
    PageFooter,
    PageHeader,
    FileView,
    FileBottomActions,
    FilesCarousel,
    RequestReviewModal,
  },
  data() {
    return {
      loading: true,
      fileLoading: false,
      moduleLoading: false,
      activeModule: null,
      fullscreen: false,
    };
  },
  computed: {
    ...mapGetters([
      "showFilesCarousel",
      "getProject",
      "getActiveStyle",
      "getActiveFile",
      "getModal",
      "getDesignModules",
      "showProjectSideDrawer",
      "getActiveDesignModule",
      "getPermission",
      "getProjectPermissionData",
      "getProjectSideDrawerTasks",
      "getActiveSpace",
      "getActiveStyleOriginal",
    ]),
    allowedExtensionTypeForModule() {
      if (
        this.getActiveDesignModule.slug ===
          this.$config.project.modules.cadDesign &&
        this.getActiveFile
      ) {
        const idx = this.$config.project.modulesFilesFormatGroup[
          "cad-design"
        ].findIndex((types) =>
          types.includes(
            getFileExtension(this.getActiveFile?.file?.original_name)
          )
        );
        return this.$config.project.modulesFilesFormatGroup["cad-design"][idx];
      } else {
        return this.$config.project.modulesFileFormats[
          this.getActiveDesignModule.slug
        ];
      }
    },
    projectMoodBoardModule() {
      return this.getProject.modules.find(
        (module) => module.type === this.$config.project.moduleTypes.moodBoard
      );
    },
    moduleId: {
      get() {
        return this.$route.query.module_id
          ? +this.$route.query.module_id
          : null;
      },
      set(val) {
        this.changeDesignModule(val);
      },
    },
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[
        this.getActiveDesignModule?.workflow_type
      ];
    },
    abilityToUpload() {
      if (
        !this.getProject?.scheme?.settings[
          this.activeModuleWorkflowOpenTypeSlug
        ] &&
        !this.getActiveStyle.ready_to_work
      ) {
        return false;
      }

      const project_id = this.$route.params.project_id;
      const activeModule = this.getProject.modules.find(
        (module) => module.id === +this.$route.query.module_id
      );
      return (
        this.getPermission(project_id)?.design["can-upload-file-design"] &&
        this.getProjectPermissionData(project_id).availableModules.includes(
          activeModule?.slug
        )
      );
    },
    activeFileNoteData() {
      if (!this.getActiveFile?.note) {
        return null;
      }
      const user = this.getProject.team.find(
        (teamUser) => teamUser.user_id === this.getActiveFile?.note_user_id
      );
      return {
        color: this.$config.project.statusColors[this.getActiveFile?.status],
        userAvatarUrl: user?.user?.avatar?.url || "",
        firstName: user?.user?.first_name,
        lastName: user?.user?.last_name,
        updatedAt: this.getActiveFile?.note_created_at,
        note: this.getActiveFile?.note,
      };
    },
    activeSortOrder() {
      return this.getActiveStyle?.sort_order;
    },
  },
  watch: {
    "$route.query.modal_task_id": {
      handler() {
        this.openTaskModal();
      },
    },
    "$route.query.comment_id": {
      handler(val) {
        if (val) {
          this.loadDesignFiles();
          this.setActiveModule();
        }
      },
    },
    getProject: {
      async handler(val) {
        const currentStyle = val.modules
          ?.find((module) => module.id === +this.$route.query.module_id)
          ?.styles?.find((style) => style.sort_order === this.activeSortOrder);
        if (currentStyle?.status !== this.getActiveStyle?.status) {
          await this.$store.dispatch("setActiveStyle", {
            ...this.getActiveStyle,
            status: currentStyle.status,
          });
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.initData(true);
  },
  methods: {
    async initData(force) {
      this.loading = true;
      const project_id = this.$route.params.project_id;
      if (!this.$route.query.module_id) {
        try {
          await this.loadProject();
          await this.$router.replace({
            query: {
              ...this.$route.query,
              module_id: this.getProject.modules[0].id,
            },
          });
          await this.loadDesignFiles(force);
          await this.setActiveModule();
          await this.$nextTick();
          if (!this.getPermission(project_id)) {
            await this.getPermissions();
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      } else {
        await Promise.all([this.loadProject(), this.loadDesignFiles(force)])
          .then(async () => {
            this.setActiveModule();
            await this.$nextTick();
            if (!this.getPermission(project_id)) {
              await this.getPermissions();
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async loadProject() {
      const project = await this.$api.project.get(
        this.$route.params.project_id
      );
      await this.$store.dispatch("setProject", project.data);
      return project;
    },
    async setActiveModule() {
      const { module_id } = this.$route.query;
      this.activeModule = this.getProject.modules.find(
        (module) => module.id === +module_id
      );
      await this.$store.dispatch("setActiveDesignModule", this.activeModule);
      await this.$store.dispatch("setDesignModules", this.getProject.modules);
    },
    async loadDesignFiles(force) {
      const { module_id, style_id, file_id, gallery_group_id } =
        this.$route.query;
      if (
        +module_id === +this.getActiveDesignModule?.id &&
        +style_id === +this.getActiveStyleOriginal?.id &&
        !force
      ) {
        await this.setActiveFile(file_id, gallery_group_id);
        return { data: this.getActiveStyleOriginal };
      }
      this.moduleLoading = true;
      try {
        const style = await this.$api.projectModuleDesign.filesList(
          this.$route.params.project_id,
          module_id,
          style_id
        );
        style.data.items = style.data.items.map((file) => ({
          ...file,
          loading: false,
        }));

        await this.$store.dispatch("setActiveStyle", style.data);

        await this.setActiveFile(file_id, gallery_group_id);
        return style;
      } catch (error) {
        console.error(error);
      } finally {
        this.moduleLoading = false;
      }
    },

    async setActiveFile(file_id, gallery_group_id) {
      if (!this.getActiveStyle) return;
      let activeFile = null;
      let activeGroup = this.getActiveStyle.grouped_items
        ? this.getActiveStyle.grouped_items[gallery_group_id]
        : [];

      if (this.getActiveStyle?.grouped_items && !activeGroup) {
        activeGroup = Object.values(this.getActiveStyle.grouped_items)[0];
        await this.$router.$updateQueryParams({
          gallery_group_id: activeGroup?.[0]?.gallery_group_id,
        });
      } else if (!this.getActiveStyle?.grouped_items) {
        await this.$router.$updateQueryParams({
          gallery_group_id: null,
          file_id: null,
        });
      }

      activeFile = activeGroup
        ? activeGroup.find((file) => file.id === +file_id)
        : null;

      if (!activeFile && this.getActiveStyle.items.length) {
        activeFile = this.getActiveStyle.items[0];
        await this.$router.$updateQueryParams({ file_id: activeFile.id });
        activeFile = {
          ...activeFile,
          loading: +this.getActiveFile?.id !== +file_id,
        };
      } else if (activeFile) {
        activeFile = {
          ...activeFile,
          loading: +this.getActiveFile?.id !== +file_id,
        };
      }

      await this.$store.dispatch("setActiveFile", activeFile);
    },
    goBack() {
      this.$router.push(
        sessionStorage.getItem("prevUrl") &&
          sessionStorage.getItem("prevUrl") !== this.$route.fullPath
          ? sessionStorage.getItem("prevUrl")
          : {
              name: "ProjectDesign",
              params: { project_id: this.$route.params.project_id },
              query: { module: this.$route.params.module_id },
            }
      );
    },
    async createDesign(files, newVersion) {
      this.fileLoading = true;
      let formData = new FormData();
      if (
        this.getActiveDesignModule.slug ===
          this.$config.project.modules.cadDesign &&
        !newVersion
      ) {
        const cadAllowedType = [
          ...this.$config.project.modulesFilesFormatGroup["cad-design"][1],
        ];
        const cadFiles = [];

        files.forEach((file) => {
          if (cadAllowedType.includes(getFileExtension(file.name))) {
            cadFiles.push(file);
          }
        });

        Object.values(this.getActiveStyle.grouped_items).forEach(([file]) => {
          if (
            cadAllowedType.includes(getFileExtension(file.file.original_name))
          ) {
            cadFiles.push(file);
          }
        });

        // if (cadFiles.length > 1) {
        //   await this.$store.dispatch('openModal', {
        //     modalName: 'fileUploadErrorModal',
        //     data: {
        //       type: 'limitError',
        //     }
        //   });
        //   this.fileLoading = false;
        //   return
        // }
      }

      files.forEach((file, idx) => {
        formData.append(`files[${idx}][file]`, file);
        formData.append(`files[${idx}][style_id]`, this.$route.query.style_id);

        this.getActiveStyle.tags.forEach((tag) => {
          formData.append(`files[${idx}][tags][]`, tag.id);
        });
      });

      if (newVersion && this.$route.query.gallery_group_id) {
        formData.append("gallery_group_id", this.$route.query.gallery_group_id);
      }

      try {
        const res = await this.$api.projectModuleDesign.create(
          this.$route.params.project_id,
          this.$route.query.module_id,
          formData
        );
        const newFiles = res.data[res.data.length - 1].items
          .map((item) => ({
            ...item,
            request_reviews: [],
          }))
          .filter(
            (file) => file.project_module.id === +this.$route.query.module_id
          );
        const newFile = newFiles[0];
        this.$store.dispatch("setActiveStyle", {
          ...res.data[res.data.length - 1],
          items: newFiles,
        });

        this.$store.dispatch("setActiveFile", newFile);

        this.$router.$updateQueryParams({ file_id: newFile.id });

        if (!newVersion || !this.$route.query.gallery_group_id) {
          this.$router.$updateQueryParams({
            gallery_group_id: newFile.gallery_group_id,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.fileLoading = false;

        // we need to load the project to check if the module has files
        // if module don't have any files, it will be available to remove
        const project = await this.$api.project.get(this.getProject.id);
        this.$store.dispatch("setProject", project.data);
        this.$store.dispatch("setDesignModules", project.data.modules);
      }
    },
    requestReview(data) {
      if (this.$store.state.ProjectDesign.activeFile.loading) return;

      this.$store.state.ProjectDesign.activeFile.loading = true;

      this.$api.project
        .requestReview(this.$route.params.project_id, data)
        .then(async (res) => {
          const style = await this.$api.projectModuleDesign.filesList(
            this.$route.params.project_id,
            this.$route.query.module_id,
            this.$route.query.style_id
          );
          style.data.items = style.data.items.map((file) => ({
            ...file,
            loading: false,
          }));
          await this.$store.dispatch("setActiveStyle", style.data);
          this.$store.dispatch("setActiveFile", {
            ...style.data.items.find((el) => el.id === this.getActiveFile.id),
            loading: false,
          });

          this.$toast.open({
            message: "Review requested",
            type: "success",
            position: "top-right",
          });
        })
        .catch((err) => {
          this.$store.state.ProjectDesign.activeFile.loading = false;
          console.error(err);
        });
    },
    async changeDesignModule(module) {
      if (!module) return;
      const currentStyle = this.getProject?.modules
        ?.find((mod) => mod.id === +module)
        ?.styles?.find((style) => style.sort_order === this.activeSortOrder);
      if (!currentStyle) {
        return;
      }
      await this.$router.$updateQueryParams({
        module_id: module,
        style_id: currentStyle.id,
      });
      await this.loadDesignFiles();
      await this.setActiveModule();
    },
    async openTaskModal() {
      if (!this.$route.query.modal_task_id) return;

      try {
        const res = await this.$api.task.get(
          this.$route.params.project_id,
          this.$route.query.modal_task_id
        );
        let taskData = {
          ...res.data,
          due_date: new Date(res.data.due_date).getTime(),
          reminder: new Date(res.data.reminder).getTime(),
          loading: false,
          parent: { id: res.data.parent ? res.data.parent.id : null },
          children: res.data.children.map((child) => ({
            ...child,
            loading: false,
            parent: { id: res.data.parent ? res.data.parent.id : null },
            due_date: new Date(child.due_date).getTime(),
            reminder: new Date(child.reminder).getTime(),
          })),
        };

        await this.$store.dispatch("openModal", {
          modalName: "createNewTaskModal",
          data: {
            type: "edit",
            taskData,
          },
          handlers: {
            onUpdate: (task) => {
              if (this.getProjectSideDrawerTasks.data.length) {
                this.$store.dispatch("setProjectSideDrawerTabData", {
                  tab: "tasks",
                  data: this.getProjectSideDrawerTasks.data.map((sdTask) => {
                    if (task.parent && task.parent.id && sdTask.id) {
                      return {
                        ...sdTask,
                        children: sdTask.children.map((subTask) =>
                          subTask.id === task.id ? task : subTask
                        ),
                      };
                    } else if (sdTask.id === task.id) {
                      return task;
                    } else {
                      return sdTask;
                    }
                  }),
                });
              }
            },
            onClose: () => {
              this.$router.$updateQueryParams({ modal_task_id: null });
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getPermissions() {
      try {
        const permissions = await this.$api.project.permissions(
          this.getActiveSpace?.id
        );
        await this.$store.dispatch("setPermissions", permissions.data);
      } catch (err) {
        console.error(err);
      }
    },
    async updateDesignDueDate(val) {
      await this.initData(true);
    },
  },
};
</script>

<style scoped lang="scss">
.design-view-page,
.module-loading {
  z-index: 2;
  height: 100%;
  overflow: hidden;
}

.project-footer {
  z-index: 4;
}

.status-note {
  margin-top: calc(22px + 42px);
  margin-right: 42px;
}
.project-title {
  max-width: 400px;
}
</style>
