<template>
  <div class="d-flex fill-height overflow-y-auto overflow-x-hidden scrollbar-thin" ref="wrapper">
    <div class="d-flex flex-column flex-fill">
      <div class="flex-initial">
        <v-expansion-panels flat tile multiple v-model="openedList">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0 font-weight-semi-bold text-body">
              Technical drawing
              <template v-slot:actions>
                <IconChevronDown width="15" class="icon-transition" style="margin-top: 1px;"/>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">
              <div class="space-y-1">
                <div
                  v-if="techDesignFiles.length"
                  class="file-carousel"
                >
                  <div class="py-1" v-for="(file) in techDesignFiles" :key="file.id">

                    <FilePreviewCard :file="file" @click="go(file)"
                                     :abilityToUpload="abilityToUpload"
                                     @newVersion="files => $emit('createDesign', {files, newVersion: true})"
                                     :class="{'file-slide__active': +file.gallery_group_id === +$route.query.gallery_group_id}" @delete="deleteItems"/>
                  </div>
                </div>
                <UIFilesUploader
                  :files-formats="$config.project.modulesFilesFormatGroup['cad-design'][0]"
                  :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
                  multiple
                  :disabled="!abilityToUpload"
                  @onChange="files => $emit('createDesign', {files, newVersion: false})"
                  class="design-view-upload-file mx-auto fill-width d-flex flex-column align-center justify-center relative rounded mb-1 text-center white gray-60--text overflow-hidden cursor-pointer"
                >
                  <IconUpload width="25" class="mb-3 accent--text"/>
                  <div class="text-captions-2 font-weight-light">
                    <template v-if="abilityToUpload">
                      Drag and Drop here
                      <p class="mb-0 accent--text font-weight-medium text-decoration-underline">or browse to add</p>
                    </template>
                    <template v-else>
                      You don't have permissions to upload files into {{ getActiveDesignModule.title }} module
                    </template>
                  </div>
                </UIFilesUploader>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0 font-weight-semi-bold text-body">
              3D CAD file
              <template v-slot:actions>
                <IconChevronDown width="15" class="icon-transition" style="margin-top: 1px;"/>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2">
              <div class="space-y-1">
                <div
                  v-if="cadFiles.length"
                  class="file-carousel"
                >
                  <div class="py-1" v-for="(file) in cadFiles" :key="file.id">
                    <FilePreviewCard :file="file" @click="go(file)"
                                     :abilityToUpload="abilityToUpload"
                                     @newVersion="files => $emit('createDesign', {files, newVersion: true})"
                                     :class="{'file-slide__active': +file.gallery_group_id === +$route.query.gallery_group_id}" @delete="deleteItems"/>
                  </div>
                </div>
                <UIFilesUploader
                  :files-formats="$config.project.modulesFilesFormatGroup['cad-design'][1]"
                  :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
                  multiple
                  :disabled="!abilityToUpload"
                  @onChange="files => $emit('createDesign', {files, newVersion: false})"
                  class="design-view-upload-file mx-auto fill-width d-flex flex-column align-center justify-center relative rounded mb-1 text-center white gray-60--text overflow-hidden cursor-pointer"
                >
                  <IconUpload width="25" class="mb-3 accent--text"/>
                  <div class="text-captions-2 font-weight-light">
                    <template v-if="abilityToUpload">
                      Drag and Drop here
                      <p class="mb-0 accent--text font-weight-medium text-decoration-underline">or browse to add</p>
                    </template>
                    <template v-else>
                      You don't have permissions to upload files into {{ getActiveDesignModule.title }} module
                    </template>
                  </div>
                </UIFilesUploader>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>
    </div>
  </div>

</template>

<script>
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import {mapGetters} from "vuex";
import {getFileExtension, getFileName} from "@/utils/helpers";
import FilePreviewCard from "@/views/project/design-view/FilePreviewCard.vue";

export default {
  name: "CadDesignBar",
  props: {
    abilityToUpload: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      openedList: []
    }
  },
  components: {
    FilePreviewCard,
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),

    UIFilesUploader
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getActiveStyle',
      'getActiveFile',
      'getActiveDesignModule',
      'getPermission',
      'getProjectPermissionData',
    ]),
    groupedItems() {
      return this.getActiveStyle?.grouped_items || {}
    },
    carouselFiles() {
      return Object.values(this.groupedItems)?.map(item => {
        return {
          ...item[0],
          can_delete: item.every(file => this.deleteDisable(file)),
          download_loading: item.every(file => this.deleteDisable(file)),
          all_ids: item.map(file => file.id)
        }
      }) || []
    },

    techDesignFiles() {
      return this.carouselFiles.filter(file => !this.$config.project.modulesFilesFormatGroup['cad-design']?.[1]?.includes?.(getFileExtension(file?.file?.original_name)))
    },
    cadFiles() {
      return this.carouselFiles.filter(file => this.$config.project.modulesFilesFormatGroup['cad-design']?.[1]?.includes?.(getFileExtension(file?.file?.original_name)))
    },
    deleteDisable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return item => {
        return (item.created_by === this.getAuthId ||
          this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager)
        ) && this.getPermissionByModule('can-delete-file-design');
      }
    },
  },
  mounted() {
    const allEmpty = this.cadFiles.length === 0
    if(this.techDesignFiles.length > 0 || allEmpty) {
      this.openedList.push(0)
    }

    this.openedList.push(1) // Always open 3D CAD file
  },
  methods: {
    getFileExtension,
    getFileName,
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      const activeModule = this.getProject.modules.find(module => module.id === +this.$route.query.module_id);
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(activeModule.slug);
    },
    deleteItems(fileDeleted) {
      if (!fileDeleted.can_delete) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete all files?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.projectModuleDesign.deleteFiles(this.$route.params.project_id, this.$route.query.module_id, {items: fileDeleted.all_ids});

              const items = this.getActiveStyle.items.filter(file => !fileDeleted.all_ids.includes(file.id));
              const groupIdx = +Object.keys(this.getActiveStyle.grouped_items).indexOf(String(fileDeleted.gallery_group_id))

              await this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

              if (this.getActiveStyle.grouped_items && !this.getActiveStyle.grouped_items[fileDeleted.gallery_group_id]) {
                const id = Object.keys(this.getActiveStyle.grouped_items)[groupIdx <= 0 ? 0 : groupIdx - 1]
                this.$router.$updateQueryParams({gallery_group_id: id});
                if (this.getActiveStyle.grouped_items[id]) {
                  await this.$store.dispatch('setActiveFile', {
                    ...this.getActiveStyle.grouped_items[id][0],
                    loading: true
                  });
                  this.$router.$updateQueryParams({file_id: this.getActiveStyle.grouped_items[id][0].id});
                } else {
                  await this.$store.dispatch('setActiveFile', null);
                  await this.$store.dispatch('setActiveFiles', []);
                  this.$router.$updateQueryParams({file_id: ''});
                }
              } else if (!this.getActiveStyle.grouped_items) {
                this.$router.$updateQueryParams({gallery_group_id: ''});
                await this.$store.dispatch('setActiveFile', null);
                await this.$store.dispatch('setActiveFiles', []);
                this.$router.$updateQueryParams({file_id: '', gallery_group_id: ''});
              }

            } catch (error) {
              console.error(error);
            } finally {
              const items = this.getActiveStyle.items.map(file => file.loading ? {...file, loading: true} : file);
              await this.$store.dispatch('setActiveStyle', {...this.getActiveStyle, items});

              // we need to load the project to check if the module has files
              // if module don't have any files, it will be available to remove
              const project = await this.$api.project.get(this.getProject.id);
              await this.$store.dispatch('setProject', project.data);
              await this.$store.dispatch('setDesignModules', project.data.modules);
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
        },
      });
    },
    go(file) {
      if (this.$route.query.gallery_group_id === file.gallery_group_id) return;
      this.$router.$updateQueryParams({gallery_group_id: file.gallery_group_id, file_id: file.id});
      this.$store.dispatch('setActiveFile', file);
    },
  },
}
</script>

<style scoped lang="scss">
.design-view-upload-file {
  box-shadow: 0 0 0 1px var(--v-gray-30-base);
  height: 88px;
  width: 118px;
  z-index: 1;

  &.active-dropzone {
    box-shadow: 0 0 0 1px var(--v-accent-base);
  }
}



:deep(.v-expansion-panel) {
  margin-top: 0 !important;
  margin-bottom: 20px;
  .v-expansion-panel-header {
    min-height: auto !important;
  }


  &.v-expansion-panel--active .icon-transition {
    transform: rotate(180deg);
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

}
</style>
