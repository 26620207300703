<template>
  <div
    :class="['design-draw-mode absolute fill-width fill-height flex-column align-center', {'is-loading': getActiveFile.loading}]">
    <div :class="['canvas-wrap my-auto', {'is-loading': loading || filesLoading}]" ref="canvas_wrap">
      <v-flex v-if="!getActiveFile.loading && filesLoading"
              class="file-loading absolute inset-0 d-flex justify-center align-center">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </v-flex>

      <UIFilesUploader
        :files-formats="$config.filesystem.project.design.drawImage.acceptedUploadFormats"
        :max-file-size="$config.filesystem.project.design.drawImage.maxUploadFileSize"
        :max-files-quantity="10"
        :disabled="!getPermission($route.params.project_id).design['can-edit-freehand-design'] || getActiveDesignMode !== $config.project.designModes.draw"
        :input-file-select="false"
        multiple
        @onChange="addFiles"
        :class="['canvas-wrap', {
          'is-loading': loading || filesLoading,
          'cursor-grab': getDrawModeData.mode === $config.project.drawModes.handmode && !zoomState.panning,
          'cursor-grabbing': getDrawModeData.mode === $config.project.drawModes.handmode && zoomState.panning,
        }]"
      >
        <canvas ref="canvas"/>
        <div
          v-show="contextMenuData.show"
          :style="{top: `${contextMenuData.y}px`, left: `${contextMenuData.x}px`}"
          class="context-menu"
          ref="context_menu"
        >
          <v-list nav dense color="gray-10">
            <v-list-item-group>
              <v-list-item @click="deleteObject">
                <v-list-item-title class="d-flex align-center text-captions-1">
                  <IconDelete width="16" class="mr-2 gray-60--text"/>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </UIFilesUploader>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {fabric} from "fabric";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import {BASE_WIDTH, BASE_HEIGHT, VIEWPORT_WIDTH, VIEWPORT_HEIGHT} from "@/config";
import {getFileExtension} from "@/utils/helpers";

export default {
  name: 'DrawMode',
  props: {
    zoom: {
      type: Number,
      default: 1
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: BASE_WIDTH
    },
    height: {
      type: Number,
      default: BASE_HEIGHT
    },
    maxZoom: {
      type: Number,
      default: 1
    },
    minZoom: {
      type: Number,
      default: 1
    }
  },
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),

    UIFilesUploader,
  },
  data() {
    return {
      filesLoading: true,
      loading: false,
      canvas: null,
      drawItems: [],
      selectedItems: [],
      brushWidth: 12,
      drawObjectControls: {
        transparentCorners: false,
        cornerStyle: 'circle',
        cornerSize: '10',
        cornerColor: '#6A47E9',
        borderColor: '#6A47E9',
      },
      contextMenuData: {
        show: false,
        x: 0,
        y: 0,
        classes: [],
        targetObject: null,
        buttonIcon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Crect x='0.613281' width='20' height='19.9977' rx='5' fill='%23DCDDDE'/%3E%3Cpath d='M15.4637 11.5891H15.1106C15.082 11.5813 15.056 11.5683 15.0275 11.5631C14.2847 11.4489 13.6978 10.8308 13.6485 10.1063C13.5914 9.29602 14.0536 8.61043 14.8067 8.38709C14.9054 8.35853 15.0093 8.33775 15.1106 8.31178H15.4637C15.4871 8.31957 15.5079 8.33256 15.5339 8.33515C16.2194 8.44682 16.7414 8.94024 16.8895 9.61545C16.8998 9.66739 16.9154 9.71933 16.9284 9.77126V10.1244C16.9206 10.1478 16.9076 10.1712 16.905 10.1946C16.796 10.8698 16.3155 11.384 15.6481 11.5398C15.5832 11.5554 15.5235 11.5735 15.4637 11.5891L15.4637 11.5891ZM5.48111 8.31177L5.83432 8.31178C5.86289 8.31957 5.88886 8.33256 5.91742 8.33515C6.66534 8.45202 7.24966 9.07269 7.2964 9.80502C7.34834 10.6127 6.88089 11.2957 6.12518 11.5164C6.02908 11.545 5.93041 11.5658 5.83431 11.5891H5.48112C5.45775 11.5813 5.43698 11.5684 5.4136 11.5632C4.7384 11.4385 4.29692 11.0464 4.08656 10.3919C4.058 10.3062 4.04242 10.2153 4.01904 10.127V9.77386C4.02683 9.75049 4.03982 9.72712 4.04242 9.70374C4.15408 9.02594 4.63192 8.51434 5.29934 8.35853C5.36166 8.34293 5.42139 8.32735 5.48111 8.31177ZM10.2959 8.31178H10.649C10.6776 8.31957 10.7036 8.33256 10.7322 8.33775C11.4775 8.45461 12.0774 9.09866 12.1085 9.8258C12.1449 10.6438 11.6515 11.3372 10.8906 11.532C10.8101 11.5528 10.7296 11.5709 10.649 11.5891H10.2959C10.2673 11.5813 10.2413 11.5684 10.2128 11.5632C9.46744 11.4489 8.86753 10.8023 8.83378 10.0751C8.79483 9.25707 9.29085 8.56368 10.0518 8.36891C10.1349 8.34814 10.2154 8.32996 10.2959 8.31178Z' fill='%2381848B'/%3E%3C/svg%3E",
      },
      updateTimeout: null,
      runUpdateDrawModeDataWatcher: true,
      zoomState: {
        start: {x: 0, y: 0},
        panning: false
      },
      scaleCoef: 1.05
    }
  },
  computed: {
    ...mapGetters([
      'getActiveFile',
      'getActiveStyle',
      'getDrawModeData',
      'getDrawModeHistory',
      'getActiveDesignMode',
      'getPermission',
    ]),
    getMaxIndex() {
      return this.getActiveFile.draw_items.length ? Math.max(...this.getActiveFile.draw_items.map(item => parseInt(item.z_index, 10))) : 0
    },
    handMode() {
      return this.getActiveDesignMode === this.$config.project.designModes.hand
    },
    isImage() {
      return this.$config.filesystem.fileTypes.image.includes(getFileExtension(this.getActiveFile.file.original_name))
    },
    allowEdit() {
      return this.getPermission(this.$route.params.project_id).design['can-edit-freehand-design']
    }
  },
  watch: {
    visible() {
      this.toggleObjectVisible()
    },

    width(newWidth, oldWidth) {
      const T = this.canvas.viewportTransform
      this.canvas.setWidth(this.width)


      T[4] = ((newWidth - oldWidth) / 2) + T[4]

      this.enclose(this.canvas)
    },

    height(newHeight, oldHeight) {
      const T = this.canvas.viewportTransform
      this.canvas.setHeight(this.height)

      T[5] = ((newHeight - oldHeight) / 2) + T[5]

      this.enclose(this.canvas)
    },
    'getActiveDesignMode': {
      handler(newVal, oldVal) {
        if (oldVal === this.$config.project.designModes.draw) {
          this.canvas.discardActiveObject();
          this.canvas.renderAll();
        }
        this.toggleDragMode(this.handMode || this.getActiveDesignMode !== this.$config.project.designModes.draw)
      }
    },
    'getDrawModeData': {
      handler(newVal, oldVal) {
        if (newVal.mode !== oldVal.mode) {
          this.setMode(newVal.mode);
        }

        // this is fucking crazy shit, I need some gun to kill myself
        // definitely needs to be redone
        if (!this.runUpdateDrawModeDataWatcher) return;

        if (newVal.color !== oldVal.color || newVal.brush !== oldVal.brush || newVal.fontSize !== oldVal.fontSize) {
          this.canvas.freeDrawingBrush.color = newVal.color;
          this.canvas.freeDrawingBrush.width = newVal.brush;

          if (this.selectedItems.length) {
            const canvasObject = this.canvas.getActiveObjects()[0];

            canvasObject.set({
              fill: canvasObject.fill ? newVal.color : '',
              stroke: canvasObject.stroke ? newVal.color : '',
              strokeWidth: canvasObject.strokeWidth ? newVal.brush : '',
              fontSize: canvasObject.fontSize ? newVal.fontSize : '',
            });

            this.drawItems.forEach(object => {
              if (canvasObject.id === object.id) {
                object.color = newVal.color;
                object.font_size = newVal.fontSize;
                object.brush = newVal.brush;
              }
            });

            if (this.updateTimeout) {
              clearTimeout(this.updateTimeout);
            }

            this.updateTimeout = setTimeout(() => {
              this.updateObjects();
            }, 1000);

            this.canvas.renderAll();
          }
        }
      }
    },
    'getActiveFile.id': {
      handler() {
        this.setBackgroundImage()
        this.renderObjects(this.getActiveFile.draw_items);
        const T = this.canvas.viewportTransform
        T[4] = 0
        T[5] = 0
        this.canvas.setZoom(1)
        this.$nextTick(this.emitStateEvent)
      }
    }
  },
  mounted() {
    this.fixFabricJSTouchScroll();

    this.initCanvas()
    this.setBackgroundImage()


    this.canvas.freeDrawingBrush.color = this.getDrawModeData.color;
    this.canvas.freeDrawingBrush.width = this.getDrawModeData.brush;

    this.createObjectMenuControl();
    this.renderObjects(this.getActiveFile.draw_items);
    this.bindCanvasEvents();

    this.$eventBus.$on('drawModeHistoryEvent', data => {
      if (data.eventType === 'stepBack') {
        this.stepBack();
      } else if (data.eventType === 'stepForward') {
        this.stepForward();
      }
    });

    this.$eventBus.$on('addStyleDrawModeImages', data => {
      this.addFiles(data);
    });

    this.toggleObjectVisible()
    this.toggleDragMode(this.handMode || this.getActiveDesignMode !== this.$config.project.designModes.draw)
    this.setMode(this.getDrawModeData.mode)

    this.$eventBus.$on('fitBounds', data => {
      this.fitBounds(data);
    });
  },
  beforeDestroy() {
    this.unbindCanvasEvents()
    this.$eventBus.$off('drawModeHistoryEvent');
    this.$eventBus.$off('addStyleDrawModeImages');
    this.$store.dispatch('updateDrawModeData', {mode: ''});
    this.$store.dispatch('updateDrawModeHistory', {
      loading: false,
      index: 0,
      steps: [],
      defaultStep: [],
    });
  },
  methods: {
    createObjectMenuControl() {
      const img = document.createElement('img');
      img.src = this.contextMenuData.buttonIcon;
      img.width = 24;
      img.height = 24;

      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 16,
        offsetX: -16,
        sizeX: 20, sizeY: 20,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, transform) => {
          this.contextMenuData = {
            show: true,
            x: eventData.offsetX,
            y: eventData.offsetY,
            targetObject: transform.target,
          }
        },
        render: renderIcon,
        cornerSize: 20,
      });

      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        const size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, size, size);
        ctx.restore();
      }
    },
    renderObjects(drawItems) {
      if (!drawItems) {
        drawItems = []
      }
      this.drawItems = [];
      this.selected = [];
      this.canvas.remove(...this.canvas.getObjects());
      const drawFuncs = [];

      drawItems.sort((a, b) => a.z_index > b.z_index ? 1 : -1).forEach(item => {
        this.drawItems.push(item);
        drawFuncs.push(this.drawObject(this.transformObjectFromBackend(item)));
      });

      Promise.all(drawFuncs).then(objects => {
        objects.forEach(object => {
          this.canvas.add(object);
        });
        this.filesLoading = false;
      });

      if (!this.allowEdit) {
        this.canvas.forEachObject(object => {
          object.selectable = false;
        });
      }
    },
    drawObject(object) {
      return new Promise(resolve => {
        let shape;
        const {
          id,
          type,
          top,
          left,
          angle = 0,
          width,
          height,
          scaleX,
          scaleY,
          color,
          brush,
          z_index,
          image,
          fontSize
        } = object;
        const render = obj => {
          if (obj !== undefined) {
            obj.set({
              objectCaching: false,
              perPixelTargetFind: type !== this.$config.project.drawModes.text,
              ...this.drawObjectControls
            });
            resolve(obj);
          }
        }
        switch (object.type) {
          case this.$config.project.drawModes.path: {
            shape = new fabric.Path(object.path, {
              id, type, top, left, width, height, scaleX, scaleY, angle, z_index, image,
              strokeWidth: brush,
              strokeLineCap: 'round',
              stroke: color,
              fill: '',
            });
            render(shape);
            break;
          }
          case this.$config.project.drawModes.image: {
            fabric.Image.fromURL(`${this.$filesUrl}/${image}`, img => {
              img.set({id, type, top, left, scaleX, scaleY, angle, z_index, image});
              render(img);
            }, {crossOrigin: 'Anonymous'});
            break;
          }
          case this.$config.project.drawModes.text: {
            shape = new fabric.IText(object.text, {
              id, type, top, left, angle, z_index, fontSize,
              fill: color,
              fontFamily: 'Inter',
              fontWeight: 700,
              cursorColor: '#55575D', // gray-80
            });
            shape.setControlsVisibility({
              bl: false, br: false, mb: false,
              ml: false, mr: false,
              tl: false, tr: false, mt: false,
              mtr: false,
            });
            render(shape);
            break;
          }
        }
      })
    },
    bindCanvasEvents() {
      this.canvas.on('mouse:wheel', this.onMouseWheel);
      this.canvas.on('mouse:down', this.onMouseDown);
      this.canvas.on('mouse:move', this.onMouseMove);
      this.canvas.on('mouse:up', this.onMouseUp);

      if (this.allowEdit) {
        this.canvas.on('path:created', event => {
          this.updateHistory();

          event.path.id = new Date().getTime();
          Object.assign(event.path, this.drawObjectControls);

          this.drawItems.push({
            ...this.transformObjectToBackend(event.path),
            type: 'path',
            brush: event.path.strokeWidth,
            path: event.path.path.map(pathChunk => pathChunk.join(' ')).join(' '),
            z_index: this.getMaxIndex + 1,
          });
          this.updateObjects();
        });

        this.canvas.on('object:modified', event => {
          this.updateHistory();

          this.drawItems = this.drawItems.map(item => {
            if (item.id === event.target.id) {
              return {
                ...this.transformObjectToBackend(event.target),
                id: event.target.id,
                type: event.target.type,
                path: event.target.path ? event.target.path.map(pathChunk => pathChunk.join(' ')).join(' ') : '',
              }
            }
            return item;
          });
          this.updateObjects();
        });

        this.canvas.on('selection:created', event => {
          event.target.set(this.drawObjectControls)
          this.updateDrawOptionsMenu(event);
          this.updateHistory();

          this.selectedItems = [...event.selected];

          this.updateZIndex(event);
        });

        this.canvas.on('selection:updated', event => {
          this.updateDrawOptionsMenu(event);

          this.selectedItems = [...event.selected];
          this.updateZIndex(event);
        });

        this.canvas.on('selection:cleared', () => {
          this.selectedItems = [];
        });
      }
    },
    unbindCanvasEvents() {
      this.canvas.off('mouse:wheel');
      this.canvas.off('mouse:down');
      this.canvas.off('mouse:move');
      this.canvas.off('mouse:up');

      this.canvas.off('path:created');

      this.canvas.off('object:modified');

      this.canvas.off('selection:created');

      this.canvas.off('selection:updated');

      this.canvas.off('selection:cleared');
    },

    deleteObject() {
      this.updateHistory();


      if ('_objects' in this.contextMenuData.targetObject) {
        this.contextMenuData.targetObject._objects.forEach(obj => {
          this.canvas.remove(obj);
        })

        this.drawItems = this.drawItems.filter(item => !this.contextMenuData.targetObject._objects.find(obj => obj.id === item.id));
      } else {
        this.canvas.remove(this.contextMenuData.targetObject);
        this.drawItems = this.drawItems.filter(item => item.id !== this.contextMenuData.targetObject.id);
      }

      this.canvas.discardActiveObject();
      this.hideContextMenu();
      this.updateObjects();
    },
    async stepBack() {
      let defaultStep = null;

      if (this.getDrawModeHistory.index === this.getDrawModeHistory.steps.length) {
        defaultStep = JSON.stringify(this.drawItems);
      }

      await this.$store.dispatch('updateDrawModeHistory', {
        defaultStep: defaultStep || this.getDrawModeHistory.defaultStep,
        index: this.getDrawModeHistory.index - 1,
        loading: true,
      });

      this.filesLoading = true;
      this.renderObjects(JSON.parse(this.getDrawModeHistory.steps[this.getDrawModeHistory.index]));
      this.updateObjects();
    },
    async stepForward() {
      await this.$store.dispatch('updateDrawModeHistory', {
        index: this.getDrawModeHistory.index + 1,
        loading: true,
      });

      let stepItems = [];

      if (this.getDrawModeHistory.index === this.getDrawModeHistory.steps.length) {
        stepItems = JSON.parse(this.getDrawModeHistory.defaultStep);
      } else {
        stepItems = JSON.parse(this.getDrawModeHistory.steps[this.getDrawModeHistory.index]);
      }

      this.filesLoading = true;
      this.renderObjects(stepItems);
      this.updateObjects();
    },
    updateHistory() {
      if (!this.allowEdit) {
        return;
      }

      let steps = this.getDrawModeHistory.steps;

      if (this.getDrawModeHistory.index < steps.length) {
        steps.slice(0, this.getDrawModeHistory.index);
      }

      steps.push(JSON.stringify(this.drawItems));
      if (steps.length > 20) {
        steps.shift();
      }

      this.$store.dispatch('updateDrawModeHistory', {
        steps,
        index: steps.length,
      });
    },
    updateZIndex(event) {
      event.selected.forEach(object => {
        object.z_index = this.getMaxIndex + 1;
        object.moveTo(object.z_index);
      });
      this.drawItems = this.drawItems.map(item => {
        if (this.selectedItems.find(obj => obj.id === item.id)) {
          return {...item, z_index: this.getMaxIndex + 1}
        }
        return item;
      })
      this.updateObjects();
    },
    updateObjects() {
      this.loading = true;
      this.$api.projectModuleDesign.update(this.$route.params.project_id, this.$route.query.module_id, this.getActiveFile.id, {
        draw_items: this.drawItems,
      })
        .then(res => {
          const activeStyle = {
            ...this.getActiveStyle,
            items: this.getActiveStyle.items.map(item => {
              if (item.id === this.getActiveFile.id) {
                return {...item, draw_items: res.data}
              }
              return item;
            })
          };

          this.$store.dispatch('setActiveStyle', activeStyle);
          this.$store.dispatch('updateDrawModeHistory', {loading: false});
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    transformObjectToBackend(object) {
      const {
        id, type, width, height, left, top, scaleX = 1, scaleY = 1, color, fill, stroke, strokeWidth, brush = null,
        image = null, text = '', fontSize = null, path = '', angle = 0, z_index = 1
      } = object;
      return {
        id, type, width, height, left, top, scaleX, scaleY, image, text, path, angle, z_index,
        font_size: fontSize,
        color: color || fill || stroke,
        brush: brush || strokeWidth
      }
    },
    transformObjectFromBackend(object) {
      const {font_size} = object;
      return {
        ...object,
        fontSize: font_size,
      }
    },
    addFiles(files) {
      this.updateHistory();
      this.setMode(this.$config.project.drawModes.freehand);

      files.forEach((file, idx) => {
        const reader = new FileReader();
        const imgWidth = 150;
        const offset = 24;

        reader.onload = () => {
          fabric.Image.fromURL(reader.result, async img => {
            img.id = new Date().getTime();
            img.z_index = this.getMaxIndex + 1;
            img.scaleToWidth(imgWidth);

            const createdFile = await this.createFile(file);
            img.image = createdFile.slug || createdFile?.download_url?.split('/')?.pop(); // createdFile?.download_url?.split('/')?.pop() fallback to last url part
            img.top = this.canvas.getCenter().top + offset * idx - imgWidth / 2;
            img.left = this.canvas.getCenter().left + offset * idx - imgWidth / 2;
            Object.assign(img, this.drawObjectControls);

            this.canvas.add(img)

            this.drawItems.push({
              ...this.transformObjectToBackend({
                ...img,
                width: img.getScaledWidth(),
                height: img.getScaledHeight(),
              }),
              type: 'image',
              path: '',
              image: createdFile.id,
            });

            this.updateObjects();
          });
        };

        reader.readAsDataURL(file);
      });
    },
    async createFile(file) {
      let formData = new FormData();
      formData.append(`file[]`, file);

      const res = await this.$api.filesystem.create(formData);
      return res.data[0];
    },
    hideContextMenu() {
      this.contextMenuData.show = false;
      this.contextMenuData.targetObject = null;
    },

    setMode(mode) {
      if (mode === this.$config.project.drawModes.image) return;
      this.hideContextMenu();
      switch (mode) {
        case this.$config.project.drawModes.path: {
          this.canvas.isDrawingMode = true;
          this.canvas.defaultCursor = 'crosshair';
          break;
        }
        case this.$config.project.drawModes.text: {
          this.canvas.isDrawingMode = false;
          this.canvas.defaultCursor = 'text';
          break;
        }
        case this.$config.project.drawModes.handmode: {
          this.canvas.isDrawingMode = false;
          this.canvas.defaultCursor = 'inherit';
          break;
        }
        default: {
          this.canvas.isDrawingMode = false;
          this.canvas.defaultCursor = 'initial';
        }
      }

      this.canvas.getActiveObjects().forEach(object => {
        if (object.type !== mode) {
          this.canvas.discardActiveObject();
          this.canvas.renderAll();
        }
      });
    },
    async updateDrawOptionsMenu(event) {
      const {type, color, fill, stroke, fontSize, strokeWidth} = event.target;

      this.runUpdateDrawModeDataWatcher = false;

      if (type === this.$config.project.drawModes.path) {
        await this.$store.dispatch('updateDrawModeData', {color: color || fill || stroke, brush: strokeWidth});
      }

      if (type === this.$config.project.drawModes.text) {
        await this.$store.dispatch('updateDrawModeData', {color: color || fill || stroke, fontSize});
      }

      this.$nextTick(() => {
        this.runUpdateDrawModeDataWatcher = true;
      })
    },
    fixFabricJSTouchScroll() {
      const defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
      fabric.util.object.extend(fabric.Canvas.prototype, {
        _onTouchStart: function (e) {
          const target = this.findTarget(e);
          // if allowTouchScrolling is enabled, no object was at the touch position,
          // and we're not in drawing mode, then
          // let the event skip the fabricjs canvas and do default
          // behavior
          if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
            // returning here should allow the event to propagate and be handled
            // normally by the browser
            return;
          }

          // otherwise call the default behavior
          defaultOnTouchStartHandler.call(this, e);
        }
      });
    },


    toggleObjectVisible() {
      this.canvas.forEachObject((object) => {
        object.visible = this.visible
      });
      this.canvas.renderAll();
    },
    initCanvas() {
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        width: this.width,
        height: this.height,
        selection: false,
        fireRightClick: true,
        stopContextMenu: true,
        selectionFullyContained: true,
        allowTouchScrolling: true,
      });
    },

    setBackgroundImage() {
      if (this.isImage) {
        fabric.Image.fromURL(this.getActiveFile.file.url, (oImg) => {
          const {scale, x, y} = this.cropImage(oImg)

          this.canvas.setBackgroundImage(oImg, this.canvas.renderAll.bind(this.canvas), {
            left: x,
            top: y,
            scaleX: scale,
            scaleY: scale
          });
        });
      } else {
        this.canvas.backgroundImage = 0
      }
    },

    cropImage(img) {
      // get the scale
      const scale = Math.min(BASE_WIDTH / img.width, BASE_HEIGHT / img.height);
      // get the top left position of the image

      const x = (this.canvas.width / 2) - (img.width / 2) * scale;
      const y = (this.canvas.height / 2) - (img.height / 2) * scale;

      this.$emit('imageSize', {width: img.width, height: img.height})

      return {
        x,
        y,
        width: img.width * scale,
        height: img.height * scale,
        scale
      };
    },

    onMouseWheel(opt) {
      if (!this.isImage) return;

      const {
        e
      } = opt;
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        e.stopPropagation();
        this.zoomDelta(this.canvas, e.deltaY, e.offsetX, e.offsetY);
        this.enclose(this.canvas);

      } else {
        e.preventDefault();
        const dx = e.deltaX;
        const dy = e.deltaY;

        const T = this.canvas.viewportTransform;

        const x = this.canvas.viewportTransform[4] - dx - 0.5

        const y = this.canvas.viewportTransform[5] - dy - 0.5;

        T[4] = x
        T[5] = y

        this.canvas.requestRenderAll();
        this.enclose(this.canvas)
      }
    },
    zoomDelta(canvas, delta, x, y) {
      let zoom = canvas.getZoom();

      let direction = delta > 0 ? -1 : 1;
      zoom = Math.max(this.minZoom, Math.min(direction > 0 ? zoom * this.scaleCoef : zoom / this.scaleCoef, this.maxZoom));
      const point = {
        x,
        y
      };

      canvas.zoomToPoint(point, zoom);
      this.$nextTick(this.emitStateEvent)
    },
    setZoom(zoom) {
      if (!this.isImage) return;

      this.canvas.zoomToPoint({x: this.canvas.width / 2, y: this.canvas.height / 2}, zoom);
      this.enclose(this.canvas)
    },
    enclose(canvas) {
      const T = canvas.viewportTransform;

      const VIEWPORT_LEFT_POINT = (BASE_WIDTH - VIEWPORT_WIDTH) / 2
      const VIEWPORT_TOP_POINT = (BASE_HEIGHT - VIEWPORT_HEIGHT) / 2

      const br = fabric.util.transformPoint(new fabric.Point(VIEWPORT_LEFT_POINT + VIEWPORT_WIDTH, VIEWPORT_TOP_POINT + VIEWPORT_HEIGHT), T);

      const tl = fabric.util.transformPoint(new fabric.Point(VIEWPORT_LEFT_POINT, VIEWPORT_TOP_POINT), T);

      const {
        x: left,
        y: top
      } = tl;
      const {
        x: right,
        y: bottom
      } = br;
      const {
        width,
        height
      } = canvas;
      // calculate how far to translate to line up the edge of the object with
      // the edge of the canvas
      const dLeft = Math.abs(right - width);
      const dRight = Math.abs(left);
      const dUp = Math.abs(bottom - height);
      const dDown = Math.abs(top);
      // if the object is larger than the canvas, clamp translation such that
      // we don't push the opposite boundary past the edge
      const maxDx = Math.min(dLeft, dRight);
      const maxDy = Math.min(dUp, dDown);
      const leftIsOver = left < 0;
      const rightIsOver = right > width;
      const topIsOver = top < 0;
      const bottomIsOver = bottom > height;
      const translateLeft = rightIsOver && !leftIsOver;
      const translateRight = leftIsOver && !rightIsOver;
      const translateUp = bottomIsOver && !topIsOver;
      const translateDown = topIsOver && !bottomIsOver;
      const dx = translateLeft ? -maxDx : translateRight ? maxDx : 0;
      const dy = translateUp ? -maxDy : translateDown ? maxDy : 0;

      if (dx || dy) {
        T[4] += dx;
        T[5] += dy;
        canvas.requestRenderAll();
      }

      this.$nextTick(this.emitStateEvent)
    },
    getClientPosition(e) {
      const positionSource = e.touches ? e.touches[0] : e;
      const {
        clientX,
        clientY
      } = positionSource;
      return {
        clientX,
        clientY
      };
    },
    handlerStartDrag(opt) {
      if (!this.handMode) return
      const {
        e
      } = opt;

      this.zoomState.panning = true;
      const {
        clientX,
        clientY
      } = this.getClientPosition(e);

      this.zoomState.start.x = clientX;
      this.zoomState.start.y = clientY;
      this.canvas.selection = false;
      this.canvas.discardActiveObject();
    },
    onMouseDown(event) {
      // event.button === 1 -> "left click"
      // event.button === 2 -> "middle click"
      // event.button === 3 -> "right click"
      event.e.preventDefault();
      if (event.button === 1 || event.button === 2 || event.button === 3 && event.target === null) {
        this.hideContextMenu();
      }
      if (this.getDrawModeData.mode === this.$config.project.drawModes.text) {
        this.updateHistory();

        const obj = {
          id: new Date().getTime(),
          type: 'text',
          top: event.absolutePointer.y,
          left: event.absolutePointer.x,
          text: 'Just Type',
          color: this.getDrawModeData.color,
          fontSize: this.getDrawModeData.fontSize,
          z_index: this.getMaxIndex + 1,
        }

        this.drawObject(obj).then(res => {
          this.canvas.add(res);

          this.canvas.setActiveObject(res)
          res.enterEditing()
          res.selectAll()
        });

        this.drawItems.push({...this.transformObjectToBackend(obj)});

        this.$store.dispatch('updateDrawModeData', {mode: this.$config.project.drawModes.freehand});
        this.updateObjects();
      }
      this.handlerStartDrag(event)
    },
    onMouseMove(opt) {
      if (!this.handMode) return
      if (!this.zoomState.panning) {
        return;
      }
      const {
        e
      } = opt;
      const T = this.canvas.viewportTransform;
      const {
        clientX,
        clientY
      } = this.getClientPosition(e);
      T[4] += clientX - this.zoomState.start.x;
      T[5] += clientY - this.zoomState.start.y;

      this.canvas.requestRenderAll();
      this.zoomState.start.x = clientX;
      this.zoomState.start.y = clientY;
      this.enclose(this.canvas);
    },
    onMouseUp(opt) {
      if (opt.isClick && this.getActiveDesignMode === this.$config.project.designModes.task) {
        this.$emit('clickCanvas', opt)
      }
      this.canvas.setViewportTransform(this.canvas.viewportTransform);
      this.zoomState.panning = false;
      this.canvas.selection = true;

    },
    toggleDragMode(dragMode) {
      // We're entering dragmode
      if (dragMode) {
        // Discard any active object
        this.canvas.discardActiveObject();
        // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
        this.canvas.forEachObject((object) => {
          object.prevEvented = object.evented;
          object.prevSelectable = object.selectable;
          object.evented = false;
          object.selectable = false;
        });
        // Remove selection ability on the canvas
        this.canvas.selection = false;

        // When MouseUp fires, we set the state to idle
      } else {
        // When we exit dragmode, we restore the previous values on all objects
        this.canvas.forEachObject((object) => {
          object.prevEvented = object.evented;
          object.prevSelectable = object.selectable;
          object.evented = true;
          object.selectable = true;
        });
        // Reset the cursor
        // this.canvas.defaultCursor = 'default';
        // Restore selection ability on the canvas
        this.canvas.selection = true;
      }
    },

    emitStateEvent() {
      this.$emit('zoomChange', this.canvas.getZoom())
      this.$emit('move', this.canvas.viewportTransform)
    },
    panCenter(x, y) {
      const T = this.canvas.viewportTransform;

      const panX = ((this.canvas.getWidth() / this.zoom / 2) - x) * this.zoom
      const panY = ((this.canvas.getHeight() / this.zoom / 2) - y) * this.zoom

      T[4] = panX
      T[5] = panY
      this.enclose(this.canvas)
    },
    scrollCanvas(coordinates) {
      const transform = this.canvas.viewportTransform;

      transform[4] += -coordinates.x;
      transform[5] += -coordinates.y;

      this.canvas.setViewportTransform(transform);

      this.enclose(this.canvas)
    },
    fitBounds(fakeItems) {

      //group all the objects
      let mainImageObject = new fabric.Object({ left: 0, top: 0, width: this.width, height: this.height, fake: true });

      const fakeItemsObjects = fakeItems.map(item => {
        return new fabric.Object({
          left: item.left,
          top: item.top,
          width: item.width,
          height: item.height,
          fake: true
        });
      });

      if(!this.canvas.getObjects()?.length && !fakeItemsObjects?.length) {
        return;
      }

      const group = new fabric.Group([...this.canvas.getObjects(), mainImageObject, ...fakeItemsObjects]);
      //find the centre of the group on the canvas
      const x = (group.left + (group.width / 2)) - (this.canvas.width / 2);
      const y = (group.top + (group.height / 2)) - (this.canvas.height / 2);
      //and pan to it
      this.canvas.absolutePan({x:x, y:y});
      //now we need to decide whether width or height should determine the scaling
      //e.g. a portrait box in a landscape canvas (height) needs scaling differently to a portrait box in a portrait canvas (could be height or width)
      //or a landscape box in a portrait canvas (width)
      //work out the distance between the edges of the group and the canvas
      const heightDist = this.canvas.getHeight() - group.height;
      const widthDist = this.canvas.getWidth() - group.width;
      let groupDimension = 0;
      let canvasDimension = 0;

      if (heightDist < widthDist) {
        groupDimension = group.height;
        canvasDimension = this.canvas.getHeight();
      } else {
        groupDimension = group.width;
        canvasDimension = this.canvas.getWidth();
      }
      const zoom = (canvasDimension / groupDimension);

      const minZoom = Math.max(this.minZoom, Math.min(zoom, this.maxZoom));

      this.setZoom(minZoom)

      let items = group.getObjects();
      group._restoreObjectsState();
      this.canvas.remove(group);
      for(let i = 0; i < items.length; i++) {
        if(items[i].fake) {
          continue;
        }
        this.canvas.add(items[i]);
      }
      this.canvas.renderAll();
      this.emitStateEvent();
    }
  },
}
</script>

<style scoped lang="scss">
//.view-mode .design-draw-mode,
//.task-mode .design-draw-mode {
//  pointer-events: none;
//}
.design-draw-mode {
  z-index: 2;

  &.is-loading {
    opacity: 0;
    pointer-events: none;
  }

  .file-loading {
    background-color: rgb(255, 255, 255, .4);
  }


  .canvas-wrap {
    position: relative;
    width: calc(v-bind(width) * 1px);
    height: calc(v-bind(height) * 1px);

    &.is-loading {
      //opacity: .5;
      pointer-events: none;
    }
  }

  .context-menu {
    position: absolute;
    width: 156px;
    border-radius: 2px;
    margin-top: 4px;
    border: 1px solid var(--v-gray-60-base);
    z-index: 3;

    &.right {
      transform: translateX(-110%);
    }

    &.bottom {
      transform: translateY(-110%);
    }

    .v-list-item {
      min-height: 32px;
    }
  }
}
</style>
