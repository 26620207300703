<template>
  <v-dialog
    v-model="getModal('requestReviewModal').isOpen"
    max-width="660"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
  >
    <div class="gray-0 pt-13 px-4 sm:px-7 pb-6">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">Request Review</h5>
        <UiBtn fab color="gray-0" @click="hideModal">
          <IconCancel width="16" class="gray-60--text" />
        </UiBtn>
      </div>

      <v-divider class="mt-5 mb-8" />

      <SelectUsers
        v-if="projectTeams.length"
        :users="projectTeams"
        show-select-all-for-members
        @onChange="setReviewers"
        :errors="errors"
        class="mb-5"
      />

      <p class="mb-5 text-body gray-100--text font-weight-light">
        Styles selected for review:
      </p>

      <div class="d-flex space-x-4 mb-5 pa-3 overflow-x-auto border">
        <div
          v-for="file in reviewedFiles"
          :key="file.id"
          class="selected-review-file relative border d-flex flex-column flex-shrink-0"
        >
          <button
            v-if="reviewedFiles.length > 1"
            type="button"
            class="absolute d-flex align-center justify-center gray-60--text"
            @click="removeReviewedFile(file.id)"
          >
            <IconCancelCircle width="18" />
          </button>
          <div
            class="d-flex justify-center align-center flex-shrink-0 gray-30"
            style="height: 112px"
          >
            <img
              v-if="
                $config.filesystem.fileTypes.image.includes(
                  getFileExtension(file.items?.[0].file.original_name)
                )
              "
              :src="file.items?.[0].file.url"
              alt=""
              class="fill-height max-width-100"
            />
            <UiFileIcon
              v-else
              width="30"
              :extension="getFileName(file.items?.[0].file.original_name).ext"
            />
          </div>
          <UiProjectStatus :id="file.status" block />
          <div class="d-flex flex-column px-2 pt-2 pb-3 relative">
            <p
              class="d-flex align-center text-body font-weight-semi-bold gray-100--text mb-1"
            >
              <span
                class="overflow-hidden text-overflow-ellipsis text-no-wrap"
                >{{
                  getFileName(file.items?.[0].file.original_name).name
                }}</span
              >
              <span>{{
                getFileExtension(file.items?.[0].file.original_name)
              }}</span>
            </p>
            <span class="text-captions-2 gray-60--text"
              >Created:
              {{ timeDistance(file.items?.[0].file.created_at) }}</span
            >
          </div>
        </div>
      </div>

      <ValidationProvider
        class="mb-2"
        mode="eager"
        tag="div"
        name="note"
        vid="note"
        rules="max:128"
        v-slot="{ errors }"
      >
        <div class="mb-2 text-body font-weight-light gray-100--text">
          Notes (Optional)
        </div>
        <v-text-field
          v-model="note"
          dense
          height="35"
          outlined
          hide-details
          placeholder="Type description here..."
          :error="!!errors.length"
        />
        <div v-if="errors.length" class="mt-1 text-captions-1 error--text">
          {{ errors[0] | capitalize }}
        </div>
      </ValidationProvider>

      <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3">
        <UiBtn
          outlined
          width="146"
          color="gray-60"
          @click="hideModal"
          class="mt-4"
        >
          Cancel
        </UiBtn>
        <v-tooltip
          top
          color="rgba(47, 49, 53, 0.7)"
          :disabled="!disabledButton"
        >
          <template #activator="{ attrs, on }">
            <div v-bind="attrs" v-on="on" class="mt-4">
              <UiBtn
                width="184"
                color="accent"
                :disabled="disabledButton"
                @click="requestReview"
              >
                <IconSendMessage width="16" class="mr-2" />
                Request Review
              </UiBtn>
            </div>
          </template>
          <div class="text-captions-1">Please add at least one assignee</div>
        </v-tooltip>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDistance } from "date-fns";
import { getFileExtension, getFileName } from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn";
import SelectUsers from "@/components/common/SelectUsers";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";
import UiProjectStatus from "@/components/UI/UiProjectStatus";

export default {
  name: "RequestReviewModal",
  components: {
    UiProjectStatus,
    UiFileIcon,
    IconCancel: () => import("@/components/icons/IconCancel"),
    IconCancelCircle: () => import("@/components/icons/IconCancelCircle"),
    IconSendMessage: () => import("@/components/icons/IconSendMessage"),
    UiBtn,
    SelectUsers,
  },
  props: {
    activeModuleId: {
      type: Number,
    },
  },
  data() {
    return {
      note: "",
      projectTeams: [],
      reviewers: {
        existingUsers: [],
        invitedUsers: [],
      },
      files: [],
    };
  },
  computed: {
    ...mapGetters(["getProject", "getProjectDesigns", "getModal"]),
    reviewedFiles() {
      return this.files;
    },
    disabledButton() {
      const users = this.reviewers.existingUsers.filter(
        (member) => member.selected
      );
      const emails = this.reviewers.invitedUsers.filter(
        (member) => member.selected
      );

      const errorsEmails = emails.filter((member) => this.errors[member.email]);

      return Boolean(
        [...users, ...emails].length <= 0 || errorsEmails.length > 0
      );
    },
    errors() {
      const { designer, sketchDesigner, cadDesigner, techDesigner } =
        this.$config.project.userRole;

      const designerRoles = [
        designer,
        sketchDesigner,
        cadDesigner,
        techDesigner,
      ];
      const emails = this.reviewers.invitedUsers.filter(
        (member) => member.selected
      );

      const designers = this.getProject.team.reduce((acc, user) => {
        if (user.user_role.some((role) => designerRoles.includes(role))) {
          acc.push(user.user.email);
        }
        return acc;
      }, []);
      return emails.reduce((acc, user) => {
        if (designers.includes(user.email)) {
          acc[user.email] = "User is a Designer";
        }
        return acc;
      }, {});
    },
  },
  mounted() {
    const {
      projectManager,
      projectOwner,
      watcher,
      reviewer,
      designer,
      sketchDesigner,
      cadDesigner,
      techDesigner,
      merchandiser,
    } = this.$config.project.userRole;

    this.projectTeams = this.getProject.team.reduce((acc, user) => {
      const disabledRoles = [
        designer,
        sketchDesigner,
        merchandiser,
        cadDesigner,
        techDesigner,
        watcher,
      ];
      const roleAutoSelected = [projectManager, projectOwner, reviewer];
      if (!user.user_role.some((role) => disabledRoles.includes(role))) {
        const selected = user.user_role.some((role) =>
          roleAutoSelected.includes(role)
        );
        const member = {
          ...user,
          ...user.user,
          text: `${user.user.first_name || ""} ${user.user.last_name || ""}`,
          active: selected, // always select projectManager, projectOwner, reviewer by default
          selected: false, // always select projectManager, projectOwner, reviewer by default
          permanent: false,
        };

        acc.push(member);
        this.reviewers.existingUsers.push(member);
      }
      return acc;
    }, []);
    this.files = this.getModal("requestReviewModal").data.files;
  },
  methods: {
    getFileExtension,
    getFileName,
    timeDistance(date) {
      const res = formatDistance(new Date(date), new Date(), {
        addSuffix: true,
      });
      return res.replace("about ", "");
    },
    removeReviewedFile(file_id) {
      this.files = this.reviewedFiles.filter((file) => file.id !== file_id);
    },
    setReviewers(users) {
      this.reviewers = users;
    },
    requestReview() {
      const files = this.getModal("requestReviewModal").data.files.map(
        (file) => file.items[0].id
      );
      const users = this.reviewers.existingUsers
        .filter((member) => member.selected)
        .map((member) => member.user_id);
      const emails = this.reviewers.invitedUsers
        .filter((member) => member.selected)
        .map((member) => member.email);

      this.$emit("requestReview", {
        files,
        users,
        emails,
        note: this.note,
        module_id: this.getModal("requestReviewModal").data.module_id,
      });
      this.hideModal();
    },
    hideModal() {
      this.$emit("hideModal");
      this.$store.dispatch("closeModal", "requestReviewModal");
      this.note = "";
    },
  },
};
</script>

<style scoped lang="scss">
.border {
  border: 1px solid var(--v-gray-30-base);
}

.selected-review-file {
  width: 174px;
  button {
    top: -8px;
    right: -10px;
    border-radius: 50%;
    background-color: var(--v-gray-0-base);
  }
}
</style>
